import { Message } from 'element-ui'
export const clientLink = '/api'
export const imageUrl = location.hostname === 'admin.szzsfx.com' ? 'https://image.szzsfx.com/' : 'https://devimage.mygctong.com/'
export const imageUrl2 = location.hostname === 'admin.szzsfx.com' ? 'https://myimage.mygctong.com/' : 'https://devimage.mygctong.com/'

export const getLastDay = (year: number, month: number) => {
    let new_year = year;    //取当前的年份
    let new_month = month++;//取下一个月的第一天，方便计算（最后一天不固定）
    if (month > 12) {
      new_month -= 12;        //月份减
      new_year++;            //年份增
    }
    const new_date = new Date(new_year, new_month, 1);                //取当年当月中的第一天
    return (new Date(new_date.getTime() - 1000 * 60 * 60 * 24)).getDate();//获取当月最后一天日期
  }


/*
* 显示提示
*/
export const showmsg = (msg: string, type: 'success' | 'warning' | 'info' | 'error' = 'warning') => {
    Message({ type: type, message: msg })
}

/*
* 验证手机号
*/
export const checkPhone = (phone: string) => {
    if (!phone) {
        showmsg('请输入手机号')
        return false
    }
    if (phone.length !== 11) {
        showmsg('手机号长度不正确')
        return false
    }
    if (!(/^1[3456789]\d{9}$/.test(phone))) {
        showmsg('手机号格式不正确')
        return false
    }
    return true
}

/*
* 验证邮箱
* */
export const checkEmail = (email: string) => {
    if (!email) {
        showmsg('请输入邮箱')
        return false
    }
    if (!(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email))) {
        showmsg('邮箱不正确')
        return false
    }
    return true
}

/*
* */
export const showTagRE = (row: any) => {
    let arr = []
    var regex =  /\[([^[\]]+)\]/g;  // 正则表达式，匹配方括号内的内容

    var matches = row.match(regex);  // 获取匹配的结果数组
    if (matches) {
        for (var i = 0; i < matches.length; i++) {
            var field = matches[i].substring(1, matches[i].length - 1);  // 去除方括号获取字段
            // var field = matches[i]  // 去除方括号获取字段
            console.log(field);
            arr.push(field)
        }
    }
    return arr.toString()
}
