














































































import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import api from "@/api";
import {imageUrl} from '@/utils/common'
import {Message} from "element-ui";


@Component({
    name: "file",
    components: {}
})

export default class extends Vue {
    baseUrl = imageUrl
    radio = '1'
    // 加载状态
    loading = false;
    // 总条数
    total = 0
    addDialog = false
    softLink = ''
    dataAuth: any = {};
    model: any = {
        OSSAccessKeyId: "",
        signature: "",
        policy: "",
        key: "",
        callback: "",
        file: "",
        success_action_status: ''
    };

    copy(shareLink: any) {
        const input = document.createElement('textarea')// 直接构建input
        input.value = shareLink// 设置内容
        document.body.appendChild(input)// 添加临时实例
        input.select()// 选择实例内容
        document.execCommand('Copy')// 执行复制
        this.$message.success('复制成功')
        document.body.removeChild(input) // 删除临时实例
    }

    add() {
        this.softLink = ''
        this.radio = '1'
        this.fileList = []
        this.fileList2 = []
        this.addDialog = true
    }

    choseType() {
        this.fileList = []
        this.fileList2 = []
    }

    //上传
    fileList2: any = [];
    formData = "";
    delFile() {
        this.fileList = [];
    }
    uploadFile(file: any) {
        (this.formData as any).append("file", file.file);
        this.submitUpload()
    }
    handleChange(file: any, fileList: any) {
        console.log('fff', file, fileList);
        this.fileList2 = fileList;
    }
    protected handleExceed2(res: any) {
        Message.error({
            message: res,
            duration: 5 * 1000,
        });
    }
    handlePreview(file: any) {
        console.log(file);
    }
    handleRemove(file: any, fileList: any) {
        console.log(file, fileList);
    }
    submitF() {
        let formData = new FormData();
        formData.append("file", (this.fileList2[0] as any).raw);
        // formData.append("fileName", file.name);
        api
            .postUP('J27503', this.softLink, formData)
            .then((res: any) => {
                this.$message.success('操作成功')
                this.addDialog = false;
                this.searchTable();
                // this.fileList = [];
            })
            .catch((err: any) => {
                this.handleExceed2(err);
            });
    }



    fileList: any = []
    handleAvatarSuccess(res: any, file: any) {
        console.log('file22', file);
    }
    beforeAvatarUpload(file: any) {
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            this.$message.error("上传大小不能超过 2MB!");
        }
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        let _that = this;
        return new Promise(function (resolve, reject) {
            api
                .post("J27501", {
                    fileName: file.name,
                    softLink: _that.softLink
                })
                .then((data) => {
                    _that.dataAuth['OSSAccessKeyId'] = data.OSSAccessKeyId;
                    _that.dataAuth['signature'] = data.signature;
                    _that.dataAuth['policy'] = data.policy;
                    _that.dataAuth['key'] = data.key;
                    _that.dataAuth['callback'] = data.callback;
                    // _that.dataAuth['file'] = file;
                    _that.dataAuth['success_action_status'] = data.success_action_status;
                    resolve(true);
                })
                .catch(() => {
                    reject(false);
                });
        });
    }

    file: any = {}
    submitUpload() {

        if(this.radio == '1') {
            this.submitF()
        } else {
            (this.$refs.upload as any).submit();
            this.addDialog = false;
            setTimeout(() => {
                this.searchTable();
            }, 1000)
        }

    }

    handleExceed(files: any, fileList: any) {
        this.$message.warning(`当前限制选择 1 个文件！`);
    }

    activated() {
        this.searchTable();
    }

    search = {
        id: '',
        pagination: {
            page: 0, //页码，从0开始
            pageSize: 10, //每页数量
        }
    }
    tableData = []

    // 搜索 search:true 重置翻页
    searchTable(search = false) {
        this.loading = true;
        if (search) {
            this.search.pagination = {
                pageSize: 20,
                page: 0,
            };
        }
        api
            .post('J27502', this.search)
            .then(res => {
                this.total = res.total;
                this.tableData = res.list;
            }).finally(() =>{
            this.loading = false;
        })
    }

}
